import React from "react"
import PropTypes from "prop-types"
import Link from "@/components/ani-link"
import Img from "@/lib/gatsby-image"
import cn from "classnames"
import typograf from "@/util/typograf"
import ViewIcon from "@/assets/icons/view.inline.svg"
import * as style from "./small.modules.scss"

export default function ProjectPreviewSmall({
  title,
  slug,
  shortDescription,
  cover,
  coverColor,
  coverAsABackground,
}) {
  return (
    <Link to={`/${slug}/`} className={style.root} data-aos="fade-up">
      <div className={cn(style.coverContainer)}>
        <div
          className={cn(
            style.coverInner,
            { [style.coverPadding]: !coverAsABackground },
            "flex items-center"
          )}
          style={{
            backgroundColor: coverAsABackground ? "#1a1a1a" : coverColor,
          }}
        >
          {cover && (
            <Img
              fluid={cover.fluid}
              className={cn("flex-auto", {
                "object-cover min-h-full min-w-full": coverAsABackground,
              })}
            />
          )}
        </div>

        <div className={style.coverHover}>
          <ViewIcon />
        </div>
      </div>
      <div className={"pt-3 text-center"}>
        {title && (
          <div className={"text-x6s desktop:text-x1s"}>
            {typograf(title.title)}
          </div>
        )}
        {shortDescription && (
          <div className={"text-x6 desktop:text-x1"}>
            {typograf(shortDescription.shortDescription)}
          </div>
        )}
      </div>
    </Link>
  )
}

ProjectPreviewSmall.propTypes = {
  title: PropTypes.shape({
    title: PropTypes.string,
  }),
  slug: PropTypes.string,
  shortDescription: PropTypes.shape({
    shortDescription: PropTypes.string,
  }),
  shortDescriptionColor: PropTypes.string,
  cover: PropTypes.object,
  coverColor: PropTypes.string,
}
